import { CButton, CCard, CCardBody, CCol, CContainer, CForm, CFormFeedback, CFormInput, CInputGroup, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react'
import constants from 'src/constants'
//import CIcon from '@coreui/icons-react'
//import { cilLockLocked, cilUser } from '@coreui/icons'
import logo from '../../../assets/images/logo.png';

import '../login/_login.scss';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ResetPwd = ({pathname}) => {

	const [razpwd, setrazpwd] = useState();
	const [goPwd, setGoPwd] = useState(false);
	const [pwd1,setPwd1] = useState('');
	const [pwd2,setPwd2] = useState('');

	//console.log("pathname : ", pathname);
//	const tblUrl = window.location.pathname.split('/');
	const tblUrl = pathname.split('/');

	const recupData = async (token) => {
		return await fetch( constants.URL_API + "ax.getTokenValid.php" , {
			method: "post",
			headers: {
				"Content-Type": 'application/json'
			},
			body: JSON.stringify({token: token})
		})
		.then(data => data.json())
	}

	const getToken = async (token) =>{
		const ret = await recupData(token);
		if(ret.ret){
			setrazpwd(ret.data);
			setGoPwd(true);
		}
	}

	const handleOnChangePwd1 = (e) => {
		setPwd1(e.target.value);
	}
	const handleOnChangePwd2 = (e) => {
		setPwd2(e.target.value);
	}
	const updatePwd = async (data) => {
		return await fetch( constants.URL_API + 'ax.changepwd.php',{
			method: "post",
			headers: {
				"Content-Type": 'application/json'
			},
			body: JSON.stringify(data)
		})
		.then(data => data.json())
	}
	const handleSubmit = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		if(pwd1 !== pwd2){
			console.log("Les mots de passe sont différents")
			toast.warning("Les mots de passe ne sont pas identiques !",constants.optToaster);
		}else{
			const ret = await updatePwd( {
				motdepasse: pwd1,
				razpwd: razpwd
			});
			if(process.env.NODE_ENV === "development") console.log("handleSubmit ret : ", ret);
			if(ret.ret){
				if(ret.retOk){
					setPwd1('');
					setPwd2('');
					toast.success(ret.retMsg,constants.optToaster);
					setTimeout(() => {
						window.location.href="/";
					},5000)
				}else{
					toast.warning(ret.retMsg,constants.optToaster);
				}
			}else{
				toast.warning("un problème est survenu lors du changement de mot de passe",constants.optToaster);
			}
		}
	}

	useEffect( () => {
		// Il y a une erreur dans l'URL ou tentative de fraude
		if(process.env.NODE_ENV === "development"){
			console.log("tblUrl : ", tblUrl);
			console.log("tblUrl[2] : ", tblUrl[2]);
		}
		//if(tblUrl[3]!=='') window.location.href="/";
		var token = tblUrl[2];
		getToken(token);
	},[]);

	return (
    <div className="min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
				<CRow className="justify-content-center mb-4">
					<a href="https://www.gce-sfnp.fr/" style={{width:"auto"}}><img src={logo} /></a>
				</CRow>
				<CRow className="justify-content-center">
					{goPwd ? (
					<CCol md={4}>
						<CCard>
							<CCardBody>
								<CRow className='justify-content-center mb-4'>
									CHANGEMENT DU MOT DE PASSE
								</CRow>
								<CForm onSubmit={handleSubmit} className="mb-4">
									<CInputGroup className="mb-3">
                    <CFormInput 
											className="oldTheme" 
											type="password" 
											//label="Nouveau mot de passe"
											floatingLabel="Nouveau mot de passe"
											//placeholder='mot de passe'
											value={pwd1}
											onChange={handleOnChangePwd1}
											required
										/>
                    <CFormFeedback invalid>Ce champ est obligatoire</CFormFeedback>
                  </CInputGroup>

									<CInputGroup className="mb-4">
                    <CFormInput 
											className="oldTheme" 
											type="password" 
											floatingLabel="Nouveau mot de passe (confirmation)"
											//floatingLabel
											//placeholder='mot de passe'
											value={pwd2}
											onChange={handleOnChangePwd2}
											required
										/>
                    <CFormFeedback invalid>Ce champ est obligatoire</CFormFeedback>
                  </CInputGroup>


									<CCol xs={12} className="d-flex justify-content-center">
										<CButton type="submit" color="primary" className="btnOldtheme px-4" >
											Valider
										</CButton>
									</CCol>
								</CForm>

								<div className='justify-content-center' style={{textAlign:"center"}}>
									<a href="/" style={{fontSize:"small"}}>Cliquez ici retourner à l'identification</a>
								</div>

							</CCardBody>
						</CCard>
					</CCol>
					):(
						<CCard className="w-50">
						<CCardBody style={{textAlign:"center"}}>
							<CRow className='justify-content-center mb-2' style={{textAlign:"center"}}>
							Le lien n'est plus valide ou a déjà été traité,<br />veuillez refaire une demande<br /><br />
							</CRow>
							<CRow>
								<a href="/">Cliquez ici retourner à l'identification</a>
							</CRow>
						</CCardBody>
					</CCard>
					)}
				</CRow>
			</CContainer>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />		
		</div>
	)
}

export default ResetPwd;