import React, { Suspense, useState } from 'react'
import { useSelector } from 'react-redux';
import { HashRouter, Route, Routes } from 'react-router-dom'
//import './scss/style.scss'

import Login from './views/pages/login/Login';
import ResetPwd from './views/pages/resetpwd';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
//const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))


//class App extends Component {
const App = () => {
  const [token, setToken] = useState();
  const meUser = useSelector( state => state.userReducer.userInfo);

  if(process.env.NODE_ENV === "development") console.log("meUser : ",meUser);
  if(process.env.NODE_ENV === "development") console.log("window.location.pathname : ",window.location.pathname);


  
  if(!token && !meUser){
    if( RegExp('^\/resetpwd\/[a-zA-Z0-9]+\/$').test(window.location.pathname)){
      const path = window.location.pathname;
      document.body.classList.add('login-bg');
      document.documentElement.style.setProperty('--cui-btn-color',"#EC712E");
      window.history.replaceState(null,"","/");
      require('./views/pages/login/_login.scss');
      return (
        <ResetPwd pathname={path}/>
      );
    }else{
      document.body.classList.add('login-bg');
      document.documentElement.style.setProperty('--cui-btn-color',"#EC712E");
      window.history.replaceState(null,"","/");
      require('./views/pages/login/_login.scss');
      return (  
        <Login setToken={setToken} />
      )
    }
  }else{
    document.body.classList.remove('login-bg');
  }

  require('./scss/style.scss');
//  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/identification" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout setToken={setToken} />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
//  }
}

export default App
