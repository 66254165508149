import constants from 'src/constants';
import jwt_decode from 'jwt-decode';
import CryptoJS from 'crypto-js';

const getUserToken = () =>{
	var myToken = "";
	var remember_me = false;
	// Charge le localStorage
	myToken = sessionStorage.getItem('token');
	if(!myToken){
		// Chage le localStorage
		myToken = localStorage.getItem('token');
		if(myToken) remember_me = true;
	}
	return {token: myToken, rememberMe: remember_me};
} 

const getUser = () => {
	var myToken = "";
	// Charge le localStorage
	myToken = sessionStorage.getItem('token');
	if(!myToken){
		// Chage le localStorage
		myToken = localStorage.getItem('token');
	}
	if(myToken){
		// Verifie la signature du token
		const tblJWT = myToken.split('.');
		const header = tblJWT[0];
		const payload = tblJWT[1];
		const sign = tblJWT[2];
		const verifSign = CryptoJS.HmacSHA256( (header) + '.' + (payload), constants.KEY_PROG).toString(CryptoJS.enc.Base64url);
		if(process.env.NODE_ENV === "development") console.log("tblJWT : ", tblJWT);
		if(process.env.NODE_ENV === "development") console.log("header : ", header);
		if(process.env.NODE_ENV === "development") console.log("payload : ", payload);
		if(process.env.NODE_ENV === "development") console.log("sign : ", sign);
		if(process.env.NODE_ENV === "development") console.log("verifSign : ", verifSign);
		if( verifSign === sign )
			return jwt_decode(myToken);
	}

//	console.log("redux : myToken :", jwt_decode(myToken));
}

const initialState = {
	userInfo: getUser(),
	userToken: getUserToken(),
}

const userReducer = (state = initialState , action ) => {
	const newState = Object.assign({}, state);	

	switch(action.type){
		case 'FETCH_USER_INFO':
			newState.userInfo = getUser();
			return newState;
		case 'FETCH_USER_TOKEN' :
			newState.userToken = getUserToken();
			return newState;

		default: return state
	}
}

export default userReducer;