import React, { useState } from 'react'
import constants from 'src/constants'
import {
  CButton,
  CCard,
  CCardBody,
  //CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormCheck,
  CFormFeedback,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import logo from '../../../assets/images/logo.png';
import './_login.scss'

import CryptoJS from 'crypto-js';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useDispatch } from 'react-redux'

const loginUser = async (credentials) => {
  return fetch(constants.URL_API + 'ax.login.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
  .then(data => data.json())
}
const recall = async (credential) => {
  return fetch(constants.URL_API + 'recall.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credential)
  })
  .then(data => data.json())
}

const Login = ({setToken}) => {
  const dispatch = useDispatch();
  const [recupMDP, setRecupMDP] = useState(false);
  const [usernameOublie, setUsernameOublie] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmitOublie = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const data = {
      identifiant: usernameOublie
    }
    const ret = await recall(data);
    if(process.env.NODE_ENV === "development") console.log("recall ret : ", ret);
    if(ret.ret){
      if(ret.data === 1 ){
        NotificationManager.warning(ret.msg);
      }else if(ret.data === 2 ){
        NotificationManager.success(ret.msg);
        setTimeout(() => {
          setRecupMDP(false);
        },5000);
      }
    }else{
      NotificationManager.error("Une erreur c'est glissé dans le programme");
    }

  }

  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const ret = await loginUser({username,password});
    //const retour = JSON.parse(ret);
    if(ret.ret){
      /* / DEBUG
      if(process.env.NODE_ENV === "development"){
        console.log("e : ", e);
        console.log("username : ", username);
        console.log("password : ", password);
        console.log("rememberMe : ", rememberMe);
        console.log("token : ", ret.data);
      }
      / */
      if(ret.data !== ""){

        // Vérification du token
        const header = ret.tbldtk.h;
        const payload = ret.tbldtk.p;
        const sign = ret.tbldtk.s;
        const verifSign = CryptoJS.HmacSHA256( (header) + '.' + (payload), constants.KEY_PROG).toString(CryptoJS.enc.Base64url) ;
        /* / DEBUG
        if(process.env.NODE_ENV === "development"){
          console.log("header : ", header);
          console.log("payload : ", payload);
          console.log("sign : ", sign);
          console.log("chaine : ", header + '.' + payload);
          console.log("verifSign : ", verifSign);
          console.log("verifSign === sign : ", (verifSign === sign));
        }
        / */

        if(verifSign === sign){
          // La signature du token est valide
          if(rememberMe){
            // Local Storage
            localStorage.setItem('token',ret.data);
          }else{
            // Session Storage
            sessionStorage.setItem('token',ret.data);
          }
          // Ici dispatch le user
          dispatch({type:'FETCH_USER_INFO'});
          // Set Token
          dispatch({type:'FETCH_USER_TOKEN'});
          setToken(ret.data);
        }else{
          NotificationManager.error("Appel illicite");
        }
      }
    }else{
      console.log("notfy error",'warning');
      NotificationManager.warning("l'identifiant et le mot de passe n'ont pas été trouvés");
    }
    //return false;
  }

  return (
    <div className="min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center mb-4">
          <a href="https://www.gce-sfnp.fr/" style={{width:"auto"}}><img src={logo} /></a>
        </CRow>
        <CRow className="justify-content-center">
          { recupMDP ? (
            <CCol md={4}>
              <CCard>
                <CCardBody>
                  <CRow className='justify-content-center mb-4'>
                    IDENTIFIANTS OUBLIÉS ?
                  </CRow>
                  <CForm onSubmit={handleSubmitOublie} className="mb-4">
                    <CInputGroup className='mb-3'>
                      <CFormInput className="oldTheme" placeholder='login (code utilisateur)' autoComplete='identifiant' required onChange={e => setUsernameOublie(e.target.value)} />
                      <CInputGroupText className='icnOldTheme'>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormFeedback invalid>Ce champ est obligatoire</CFormFeedback>
                    </CInputGroup>
                    <CCol xs={12} className="d-flex justify-content-center">
                      <CButton type="submit" color="primary" className="btnOldtheme px-4">
                        Valider
                      </CButton>
                    </CCol>
                  </CForm>
                  <CRow className="txtInfo">
                    <a className="link" onClick={(e) => {e.stopPropagation(); setRecupMDP(false)}}>Cliquez ici pour revenir à l'identification</a>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
          ):(
          <CCol md={4}>
            <CCard>
              <CCardBody>
                <CRow className="justify-content-center mb-4">
                  ESPACE CLIENT 
                </CRow>
                <CForm onSubmit={handleSubmitLogin}>
                  <CInputGroup className='mb-3'>
                    <CFormInput className="oldTheme" placeholder='login (code utilisateur)' autoComplete='userid' required onChange={e=>setUsername(e.target.value)} />
                    <CInputGroupText className='icnOldTheme'>
                      <CIcon icon={cilUser} />
                    </CInputGroupText>
                    <CFormFeedback invalid>Ce champ est obligatoire</CFormFeedback>
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CFormInput className="oldTheme" type="password" placeholder='mot de passe' autoComplete='mdp' required onChange={e=>setPassword(e.target.value)} />
                    <CInputGroupText className="icnOldTheme">
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormFeedback invalid>Ce champ est obligatoire</CFormFeedback>
                  </CInputGroup>

                  <CRow className="mb-4">
                    <CCol xs={6} className="text-left">
                      <CFormCheck name="remember_me" className="frmCheck" onChange={e=>setRememberMe(e.target.checked)} id="remember_me" label="Se souvenir de moi" />
                    </CCol>
                    <CCol xs={6} className="d-flex justify-content-end">
                      <CButton type="submit" color="primary" className="btnOldTheme px-4">
                        Valider
                      </CButton>
                    </CCol>
                  </CRow>

                </CForm>
                <CRow className="mb-3 txtInfo">
                  <CCol sm={12}>
                  Vous avez perdu votre mot de passe ?<br />
                  <a className="link" onClick={(e) => { e.stopPropagation(); setRecupMDP(true);}}>cliquez ici pour le récupérer</a>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
          )}
        </CRow>
      </CContainer>
      <NotificationContainer/>
    </div>
    /*
    <div className="min-vh-100 d-flex flex-row align-items-center login-bg">
      <CContainer>
        <CRow className="justify-content-center">
          <a href="https://www.gce-sfnp.fr/" style={{width:"auto"}}><img src={logo} /></a>
        </CRow>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput placeholder="Username" autoComplete="username" />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton color="primary" className="px-4">
                          Login
                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-right">
                        <CButton color="link" className="px-0">
                          Forgot password?
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary py-5" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                    <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>
                        Register Now!
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
    */
  )
}
export default Login
