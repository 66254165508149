import { applyMiddleware, createStore } from 'redux'
import thunk from "redux-thunk";
import logger from "redux-logger";

import rootReducer from "./redux";

export default createStore(
	rootReducer,
	process.env.NODE_ENV === "production"
		? applyMiddleware(thunk)
		: (window.__REDUX_DEVTOOLS_EXTENSION__ &&
			window.__REDUX_DEVTOOLS_EXTENSION__(),
			applyMiddleware(thunk, logger))
);


/*
const initialState = {
  sidebarShow: true,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
*/
