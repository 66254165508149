const constants = {
	// URL d'API
	URL_API:  process.env.NODE_ENV === "development" ? "http://sfnp-dev/api/" : "/api/",

	// Signature des tokens
	KEY_PROG: "@CyberGab$",

	optToaster: {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "colored",
	},

	colStat: [
		"#007CF7",
		"#77BBFF",
		"#C3ECFF",
		"#FFE6D3",
		"#F9896B",
		"#03D9B3",
		"#EFCB70",
		"#FF6771",
		"#A7A5FF",
		"#FBEC3D",
		"#5C7AFF",
		"#31D9F0",
	],

	txtTooltip1 : "Confiez-nous vos dossiers<br />en ligne en complétant<br />le formulaire.<i></i>",
	txtTooltip2 : "Transmettez-nous vos impayés<br />en nombre.<i></i>",

	//FORM_URL : "https://p.monetico-services.com/test/paiement.cgi", // URL TEST
	FORM_URL : "https://p.monetico-services.com/paiement.cgi", // URL PROD
}

export default constants;