import { combineReducers } from "redux";

import sidebarReducer from "./reducers/sidebar.reducer";
import userReducer from "./reducers/user.reducer";

const rootReducer = combineReducers( {
	sidebarReducer,
	userReducer,
});

export default rootReducer;